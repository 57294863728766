import React from 'react';
import { useTranslation } from 'react-i18next';

function LanguageSelector() {
    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    const { i18n } = useTranslation();

    return (
        <div className="g-cipher-language-selector">
            <button className={i18n.language === 'sv' ? 'g-cipher-button g-cipher-button--small g-cipher-button--primary g-cipher-button--active' : 'g-cipher-button g-cipher-button--small g-cipher-button--blank'} onClick={() => changeLanguage('sv')}>Svenska</button>
            <button className={i18n.language === 'en' ? 'g-cipher-button g-cipher-button--small g-cipher-button--primary g-cipher-button--active' : 'g-cipher-button g-cipher-button--small g-cipher-button--blank'} onClick={() => changeLanguage('en')}>English</button>
        </div>
    );
}

export default LanguageSelector;