const BASE_URL = process.env.NODE_ENV === 'production'
  ? 'https://chiffer.io:443'
  : 'http://localhost:3001';

export const checkDataAvailability = async (secret) => {
  const response = await fetch(`${BASE_URL}/check/${secret}`, {
    method: 'POST',
  });

  if (!response.ok) {
    throw new Error('Data not found or already viewed.');
  }

  return response.json();
};

export const fetchSecretData = async (uuid, password) => {
  const response = await fetch(`${BASE_URL}/data/${uuid}?password=${password}`, {
    method: 'GET',
  });

  if (!response.ok) {
    const errorText = await response.text();
    throw new Error(errorText);
  }

  return response.json();
};

export const createEncryptedData = async (data, password, hours) => {
  const response = await fetch(`${BASE_URL}/create`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ data, password, hours }),
  });

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};