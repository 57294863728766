import React, { useState } from 'react';
import Slider from 'rc-slider';
import { useTranslation } from 'react-i18next';
import 'rc-slider/assets/index.css';
import '../i18n';

const TimeSelector = ({ onChange }) => {
    const { t } = useTranslation();
    const [value, setValue] = useState(1);

    const handleTimeChange = (value) => {
        onChange(value);
        setValue(value);
    };

    const marks = {
        1: t('interval_1hour'),
        24: t('interval_1day'),
        48: t('interval_2days'),
        72: t('interval_3days'),
        168: t('interval_1week'),
        336: t('interval_2weeks'),
    };

    return (
        <div className='g-cipher-time-selector'>
            <h3>{t('life')}</h3>
            <Slider
                min={1}
                max={336}
                marks={marks}
                step={null}
                value={value}
                onChange={handleTimeChange}
            />
            <div>{marks[value]}</div>
        </div>
    );
};

export default TimeSelector;