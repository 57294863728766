import React, { useState, useEffect, useCallback, useRef } from 'react';
import './App.scss';
import TimeSelector from './Components/time_selector';
import { checkDataAvailability, fetchSecretData, createEncryptedData } from './Utils/api';
import { getExpirationTime } from './Utils/dateUtils';
import { useTranslation } from 'react-i18next';
import './i18n';
import Preloader from './Components/preloader';
import LanguageSelector from './Components/language_selector';
import Modal from './Components/modal';
import "@fontsource/onest";
import "@fontsource/onest/300.css";
import "@fontsource/onest/400.css";
import "@fontsource/onest/600.css";
import "@fontsource/onest/800.css";

function App() {
  const version = '1.1.1_1.0.0'; // Frontend_Backend
  const { t, i18n } = useTranslation();
  const [data, setData] = useState('');
  const [hours, setHours] = useState(1);
  const [uuid, setUuid] = useState('');
  const [retrievedData, setRetrievedData] = useState(null);
  const [remainingTime, setRemainingTime] = useState(null);
  const [password, setPassword] = useState('');
  const [wrongPassword, setWrongPassword] = useState(false);
  const [hasPassword, setHasPassword] = useState(false);
  const [secret, setSecret] = useState(null);
  const [url, setURL] = useState('');
  const [copied, setCopied] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const textAreaRef = useRef(null);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    const metaDescription = document.querySelector('meta[name="description"]');
    const title = document.querySelector('title');
    if (metaDescription) {
      metaDescription.setAttribute('content', t('intro'));
    }
    if (title) {
      title.innerText = t('welcome') + ' - ' + t('slogan');
    }
  }, [t, i18n.language]);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, []);

  const handleCheckDataAvailability = useCallback(async (secret) => {
    try {
      const result = await checkDataAvailability(secret);
      setRemainingTime(result.remainingTime);
      setHasPassword(result.hasPassword);
      setUuid(secret);
    } catch (error) {
      console.error('Error:', error);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const secretParam = params.get('secret');
    if (secretParam) {
      setSecret(secretParam);
      handleCheckDataAvailability(secretParam);
    }
  }, [handleCheckDataAvailability]);

  const handleFetchSecretData = async () => {
    const initiallyWrongPassword = wrongPassword;
    setLoading(true);
    setWrongPassword(false);

    if (initiallyWrongPassword) {
      await new Promise(resolve => setTimeout(resolve, 2000));
    }

    try {
      const result = await fetchSecretData(uuid, password);
      setRetrievedData(result.data);
    } catch (error) {
      if (error.message === 'Password required.' || error.message === 'Incorrect password.') {
        setWrongPassword(true);
      } else {
        console.error('Error:', error);
        alert('Ett fel inträffade vid hämtning av data.');
      }
    }
    setLoading(false);
  };

  const handleEncrypt = async () => {
    setLoading(true);

    try {
      const result = await createEncryptedData(data, password, hours);
      setURL(window.location.origin + "?secret=" + result.uuid);
    } catch (error) {
      console.error('Error:', error);
      alert(t('failedToEncrypt'));
    }
    setLoading(false);
  };

  const copyText = (element) => {
    navigator.clipboard.writeText(element.target.innerText);
    setCopied(true);
  }

  const copyResult = () => {
    const text = t('encryptedMessage') + ' ' + getExpirationTime(hours * 60 * 60 * 1000, i18n.language) + '.\n\n' + url;
    navigator.clipboard.writeText(text);
    setCopied(true);
  }

  const resetApp = () => {
    setRetrievedData(null);
    setRemainingTime(null);
    setHasPassword(false);
    setSecret(null);
    setURL('');
    setCopied(false);
    setData('');
    setPassword('');
    setHours(24);
  }

  return (
    <div className="g-cipher">
      <Preloader />
      <Modal show={showModal} onClose={toggleModal}>
        <h2>{t('aboutChiffer.title')}</h2>
        <p>{t('aboutChiffer.description')}</p>
        <h3>{t('aboutChiffer.functionality.title')}</h3>
        <ul>
          <li>{t('aboutChiffer.functionality.encryption')}</li>
          <li>{t('aboutChiffer.functionality.passwordProtection')}</li>
          <li>{t('aboutChiffer.functionality.timeLimitedAccess')}</li>
          <li>{t('aboutChiffer.functionality.uniqueLinks')}</li>
          <li>{t('aboutChiffer.functionality.multilingualSupport')}</li>
        </ul>
        <h3>{t('aboutChiffer.technicalOverview.title')}</h3>
        <ul>
          <li>{t('aboutChiffer.technicalOverview.frontend')}</li>
          <li>{t('aboutChiffer.technicalOverview.backend')}</li>
          <li>{t('aboutChiffer.technicalOverview.security')}</li>
        </ul>
        <p>{t('aboutChiffer.conclusion')}</p>
      </Modal>
      <header className="g-cipher-header">
        <a href="/" className="g-cipher-header__logo">
          <img height="48" src={require('./Resources/Images/chiffer_logo.png')} alt="logo" />
          <h1>{t('welcome')}</h1>
        </a>
        <LanguageSelector />
        <p>{t('intro')}</p>
        <button className='g-cipher-header__read-more-button g-cipher-button g-cipher-button--small g-cipher-button--primary' onClick={toggleModal}>{t('read_more')}</button>
      </header>
      <main className='g-cipher-main'>
        {retrievedData ? (
          <div className='g-cipher-decryption-complete'>
            <h2>{t('retrievedData')}</h2>
            <div className="g-cipher-notification g-cipher-notification--warning">
              <p>{t('removedData')}</p>
            </div>
            <div className="g-cipher-notification">
              <h3>{t('promo.header')}</h3>
              <p>{t('promo.text')}</p>
            </div>
            {copied && (
              <div className="g-cipher-notification">
                <p>{t('copied_info')}</p>
              </div>
            )}
            <p className='g-cipher-decryption-complete__result' onClick={copyText}>{retrievedData}</p>
            <button className="g-cipher-button g-cipher-button--blank g-cipher-button--blank-inverted" onClick={resetApp}>{t('reEncrypt')}</button>
          </div>
        ) : remainingTime > 3000 ? (
          <div className='g-cipher-data-form'>
            <div className={wrongPassword ? 'g-cipher-notification g-cipher-notification--warning' : 'g-cipher-notification g-cipher-notification--warning g-cipher-notification--hidden'}>
              <p>{t('wrongPassword')}</p>
            </div>
            <h2>{t('decryptData')}</h2>
            <p>{t('dataExpires')} {getExpirationTime(remainingTime, i18n.language)}</p>
            <p>{t('beware')}</p>
            {hasPassword && (
              <input
                type="password"
                placeholder={t('enterPasswordPrompt')}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            )}
            <button
              className={
                wrongPassword
                  ? "g-cipher-button g-cipher-button--primary g-cipher-button--error"
                  : "g-cipher-button g-cipher-button--primary"
              }
              onClick={handleFetchSecretData}
              disabled={hasPassword && (!password || loading)}
            >
              {loading ? t('retrievingData') : t('retrieveData')}
            </button>
          </div>
        ) : !url ? (
          <div className='g-cipher-data-form'>
            {secret !== null && (
              <div className="g-cipher-notification g-cipher-notification--warning">
                <p>{t('warning')}</p>
              </div>
            )}
            <h2>{t('encryptData')}</h2>
            <textarea
              placeholder={t('enterData')}
              value={data}
              onChange={(e) => setData(e.target.value)}
              ref={textAreaRef}
            />
            <input
              type="password"
              placeholder={t('enterPassword')}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <TimeSelector onChange={setHours} />
            <button className="g-cipher-button g-cipher-button--primary" onClick={handleEncrypt} disabled={!data || loading}>{loading ? t('encrypting') : t('encrypt')}</button>
          </div>
        ) : (
          <div className='g-cipher-encryption-complete'>
            <h2>{t('encryptData')}</h2>
            {copied && (
              <div className="g-cipher-notification">
                <p>{t('copied_link')}</p>
              </div>
            )}
            <p>{t('dataEncrypted')} <strong><nobr>{getExpirationTime(hours * 60 * 60 * 1000, i18n.language)}.</nobr></strong></p>
            <button className="g-cipher-button g-cipher-button--primary" onClick={copyResult}>{t('copy_value')}</button>
            <button className="g-cipher-button g-cipher-button--blank g-cipher-button--blank-inverted" onClick={resetApp}>{t('reEncrypt')}</button>
          </div>
        )
        }
      </main >
      <footer className="g-cipher-footer">
        <img width="75" src={require('./Resources/Images/logo.png')} alt="logo" />
        <p><small>© {new Date().getFullYear()} {t('credits')}</small></p>
        <p className='g-cipher-footer__credits'><small>Version: {version}</small></p>
      </footer>
    </div >
  );
}

export default App;
