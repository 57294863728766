export const getExpirationTime = (remainingTime, language) => {
    if (remainingTime !== null) {
        const expirationDate = new Date(Date.now() + remainingTime);

        // Använd svensk locale och anpassa formatet
        const options = {
            day: 'numeric', // dag i månaden
            month: 'long', // fullständigt namn på månaden
            hour: '2-digit', // timmar i två siffror
            minute: '2-digit', // minuter i två siffror
            hour12: false // använd 24-timmars klocka
        };

        // Formatera datumet enligt de angivna inställningarna
        const formattedDate = expirationDate.toLocaleString(language === 'sv' ? 'sv-SE' : 'en-US', options);

        // Lägg till "den" och "klockan" för att matcha önskat format
        return language === 'sv' ? `den ${formattedDate}` : formattedDate;
    }
    return '';
};