import React, { useEffect, useState } from 'react';
import { DotLottieReact } from '@lottiefiles/dotlottie-react';
import '../i18n';
import { useTranslation } from 'react-i18next';

function Preloader() {
    const { t } = useTranslation();
    const [preloaderClass, setPreloaderClass] = useState('g-cipher-preloader');
    const [renderPreloader, setRenderPreloader] = useState(true);
    const [preloaderAnimation, setPreloaderAnimation] = useState(null);
    const [preloaderAnimationLoaded, setPreloaderAnimationLoaded] = useState(false);

    useEffect(() => {
        function onComplete() {
            setTimeout(() => {
                setPreloaderClass('g-cipher-preloader g-cipher-preloader--loaded');
                setTimeout(() => {
                    setRenderPreloader(false);
                }, 750);
            }, 1500);
        }

        if (preloaderAnimation) {
            preloaderAnimation.addEventListener('complete', onComplete);
            preloaderAnimation.addEventListener('load', () => { setPreloaderAnimationLoaded(true) });
        }

        return () => {
            if (preloaderAnimation) {
                preloaderAnimation.removeEventListener('complete', onComplete);
            }
        };
    }, [preloaderAnimation]);

    const preloaderRefCallback = (preloaderAnimation) => {
        setPreloaderAnimation(preloaderAnimation);
    };

    return (
        renderPreloader && (
            <div className={preloaderClass}>
                <DotLottieReact
                    src={require('../Resources/Animations/locking.lottie')}
                    autoplay
                    style={{ width: 'clamp(150px,30vw, 300px)', height: 'clamp(150px,30vw, 300px)' }}
                    dotLottieRefCallback={preloaderRefCallback}
                />
                <h1 className={preloaderAnimationLoaded ? 'g-cipher-preloader__title g-cipher-preloader__title--loaded' : 'g-cipher-preloader__title'}>{t('welcome')}</h1>
                <p className={preloaderAnimationLoaded ? 'g-cipher-preloader__slogan g-cipher-preloader__slogan--loaded' : 'g-cipher-preloader__slogan'}>{t('slogan')}</p>
            </div>

        )
    );
}

export default Preloader;
