import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      beware: "When you click the button below, you will see the information and it will be deleted.",
      copied_link: "The link to the information has been copied to the clipboard and it is safe to send to the recipient.",
      copied_info: "The information has been copied to the clipboard.",
      copy_value: "Copy link to clipboard",
      credits: "Chiffer by Gibon Webb",
      dataEncrypted: "The information has been successfully encrypted and stored securely on the server until",
      dataExpires: "The information expires ",
      dataNotFound: "The information is not found or already viewed.",
      decryptData: "Decrypt information",
      encrypt: "Encrypt",
      encryptData: "Encrypt information",
      encryptedMessage: "The information is available at this link until",
      encrypting: "Encrypting",
      enterData: "Enter your sensitive information here",
      enterPassword: "Enter a password (optional)",
      enterPasswordPrompt: "Enter password",
      failedToEncrypt: "Failed to encrypt information.",
      failedToRetrieve: "Failed to retrieve information.",
      interval_1day: "1 day",
      interval_1hour: "1 hour",
      interval_1week: "1 week",
      interval_2days: "2 days",
      interval_2weeks: "2 weeks",
      interval_3days: "3 days",
      intro: "Chiffer is an application that provides a secure and user-friendly platform for encrypting and sharing sensitive information.",
      life: "Time until deletion",
      read_more: 'Read more about Chiffer',
      reEncrypt: "Encrypt a new message",
      removedData: "The information has been removed from the server. Be sure to copy it.",
      retrieveData: "Retrieve Data",
      retrievingData: "Retrieving Data",
      retrievedData: "Retrieved Data",
      slogan: "For information that should not fall into the wrong hands",
      warning: "The information has already been read or the time limit has passed.",
      welcome: "Chiffer",
      wrongPassword: "Wrong password entered. Please try again.",
      aboutChiffer: {
        title: "About Chiffer",
        description: `
        Chiffer is an application that provides a secure and user-friendly platform for encrypting and sharing sensitive information. By utilizing both frontend and backend technologies, Chiffer ensures that data can be protected and shared securely.
        `,
        functionality: {
          title: "Functionality",
          encryption: "Data Encryption: Users can input text data they wish to protect. This data is encrypted before being stored, ensuring that only authorized users can access it.",
          passwordProtection: "Password Protection: For additional security, users can choose to protect their encrypted data with a password. This password must be entered to decrypt and view the content.",
          timeLimitedAccess: "Time-Limited Access: Users can set a time limit for how long the encrypted data should be available. Once the time expires, the data is automatically deleted, minimizing the risk of unauthorized access.",
          uniqueLinks: "Unique Links: After data is encrypted, a unique link is generated that can be shared with recipients. This link is used to retrieve and decrypt the data.",
          multilingualSupport: "Multilingual Support: The application supports multiple languages, making it accessible to a wider audience."
        },
        technicalOverview: {
          title: "Technical Overview",
          frontend: "Frontend: Built with React, the frontend component offers an intuitive interface where users can encrypt and decrypt data. It also includes features for managing language selection and displaying modal windows for additional information.",
          backend: "Backend: The backend is built with Express and handles the encryption logic and data storage. It uses a database to store encrypted data and manages requests to create, check, and retrieve data.",
          security: "Security: The application uses modern security practices to protect data, including encryption and password management. Additionally, measures are implemented to limit the number of requests and protect against overload attacks."
        },
        conclusion: "Chiffer is designed to provide users with peace of mind when sharing sensitive information by combining simplicity with robust security."
      },
      promo: {
        header: "Like Chiffer?",
        text: "We offer custom encryption solutions with your brand on your own domain or subdomain. Contact us at webb@gibon.se to learn more."
      }
    }
  },
  sv: {
    translation: {
      beware: "När du klickar på knappen nedan kommer du få se informationen och den kommer samtidigt att raderas.",
      copied_link: "Länken till informationen har kopierats till urklipp och den är säker att skicka till mottagaren.",
      copied_info: "Informationen har kopierats till urklipp.",
      copy_value: "Kopiera länk till urklipp",
      credits: "Chiffer av Gibon Webb",
      dataEncrypted: "Informationen är framgångsrikt krypterad och lagrad säkert på servern till den",
      dataExpires: "Informationen går ut den",
      dataNotFound: "Informationen hittades inte eller har redan visats.",
      decryptData: "Avkryptera information",
      encrypt: "Kryptera",
      encryptData: "Kryptera information",
      encryptedMessage: "Informationen är tillgänglig på den här länken fram till den",
      encrypting: "Krypterar",
      enterData: "Ange din känsliga information här",
      enterPassword: "Ange ett lösenord (valfritt)",
      enterPasswordPrompt: "Ange lösenord",
      failedToEncrypt: "Misslyckades med att kryptera information.",
      failedToRetrieve: "Misslyckades med att hämta information.",
      interval_1day: "1 dag",
      interval_1hour: "1 timme",
      interval_1week: "1 vecka",
      interval_2days: "2 dagar",
      interval_2weeks: "2 veckor",
      interval_3days: "3 dagar",
      intro: "Chiffer är en applikation som erbjuder en säker och användarvänlig plattform för att kryptera och dela känslig information.",
      life: "Tid tills borttagning",
      read_more: 'Läs mer om Chiffer',
      reEncrypt: "Kryptera ett nytt meddelande",
      removedData: "Informationen har tagits bort från servern. Var noga med att kopiera den.",
      retrieveData: "Hämta information",
      retrievingData: "Hämtar information",
      retrievedData: "Hämtad information",
      slogan: "För information som inte får hamna i fel händer",
      warning: "Informationen har redan lästs eller tidsgränsen har passerat.",
      welcome: "Chiffer",
      wrongPassword: "Fel lösenord angivet. Försök igen.",
      aboutChiffer: {
        title: "Om Chiffer",
        description: `
          Chiffer är en applikation som erbjuder en säker och användarvänlig plattform för att kryptera och dela känslig information. Genom att använda både frontend- och backend-teknologier, säkerställer Chiffer att data kan skyddas och delas på ett säkert sätt.
        `,
        functionality: {
          title: "Funktionalitet",
          encryption: "Kryptering av data: Användare kan mata in textdata som de vill skydda. Denna data krypteras innan den lagras, vilket säkerställer att endast behöriga användare kan komma åt den.",
          passwordProtection: "Lösenordsskydd: För ytterligare säkerhet kan användare välja att skydda sin krypterade data med ett lösenord. Detta lösenord måste anges för att dekryptera och visa innehållet.",
          timeLimitedAccess: "Tidsbegränsad åtkomst: Användare kan ställa in en tidsgräns för hur länge den krypterade datan ska vara tillgänglig. När tiden har gått ut, tas datan bort automatiskt, vilket minimerar risken för obehörig åtkomst.",
          uniqueLinks: "Unika länkar: Efter att data har krypterats genereras en unik länk som kan delas med mottagare. Denna länk används för att hämta och dekryptera datan.",
          multilingualSupport: "Flerspråkigt stöd: Applikationen stöder flera språk, vilket gör den tillgänglig för en bredare publik."
        },
        technicalOverview: {
          title: "Teknisk översikt",
          frontend: "Frontend: Byggd med React, erbjuder frontend-komponenten ett intuitivt gränssnitt där användare kan kryptera och dekryptera data. Det finns även funktioner för att hantera språkval och visa modala fönster för ytterligare information.",
          backend: "Backend: Backend är byggd med Express och hanterar krypteringslogiken samt datalagring. Den använder en databas för att lagra krypterad data och hanterar förfrågningar för att skapa, kontrollera och hämta data.",
          security: "Säkerhet: Applikationen använder moderna säkerhetsmetoder för att skydda data, inklusive kryptering och lösenordshantering. Dessutom implementeras åtgärder för att begränsa antalet förfrågningar och skydda mot överbelastningsattacker."
        },
        conclusion: "Chiffer är designad för att ge användare trygghet när de delar känslig information, genom att kombinera enkelhet med robust säkerhet."
      },
      promo: {
        header: "Gillar du Chiffer?",
        text: "Vi erbjuder anpassade krypteringslösningar med ert varumärke på din egen domän eller subdomän. Kontakta oss på webb@gibon.se så berättar vi mer."
      }
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "sv",
    fallbackLng: "en",
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;